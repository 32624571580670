
import { defineComponent } from "vue";
import Button from "primevue/button";
import IconChevronLeft from "~icons/tabler/chevron-left";
import { Route } from "@/typings/RoutePlanner";
import IconWalk from "~icons/tabler/walk";
import IconBike from "~icons/tabler/bike";
import IconMotorbike from "~icons/tabler/motorbike";
import IconCar from "~icons/tabler/car";
import IconBus from "~icons/tabler/bus";
import IconRail from "~icons/tabler/train";
import IconChevronRight from "~icons/tabler/chevron-right";
import Divider from "primevue/divider";

export default defineComponent({
  components: {
    Button,
    IconChevronLeft,
    IconWalk,
    IconBike,
    IconMotorbike,
    IconCar,
    IconBus,
    IconRail,
    IconChevronRight,
    Divider,
  },

  computed: {
    route(): Route {
      return this.$store.getters["route-planner/route"];
    },
    icons() {
      return [
        {
          value: "walk",
          icon: IconWalk,
        },
        {
          value: "bicycle",
          icon: IconBike,
        },
        {
          value: "bicycle_rent",
          icon: IconMotorbike,
        },
        {
          value: "car",
          icon: IconCar,
        },
        {
          value: "transit",
          icon: IconBus,
        },
        {
          value: "bus",
          icon: IconBus,
        },
        {
          value: "rail",
          icon: IconRail,
        },
      ];
    },
  },

  methods: {
    goBack() {
      if (window.history.length > 1) {
        this.$router.back();
      } else {
        this.$router.push({ name: "RoutePlanner" });
      }
    },
    getDuration(route: Route): string {
      const duration = this.$moment.duration(
        this.$moment(route.endTime).diff(this.$moment(route.startTime)),
      );

      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.minutes());

      return hours >= 1 ? `${hours}h ${minutes}min` : `${minutes}min`;
    },
    getDistance(route: Route): string {
      const totalMeters = route.legs.reduce(
        (acc, leg) => acc + leg.distance.value,
        0,
      );
      const meters = Math.floor(totalMeters);
      const kilometers = Math.floor(meters / 1000);
      const remainingMeters = Math.floor(meters % 1000);

      return kilometers > 0
        ? `${kilometers} km ${remainingMeters} m`
        : `${meters} m`;
    },
    getArrivalTime(route: Route): string {
      return this.$moment(route.endTime).format("HH:mm");
    },
    getIconComponent(iconValue: string) {
      const icon = this.icons.find(
        (item) => item.value.toLowerCase() === iconValue.toLowerCase(),
      );
      return icon ? icon.icon : IconWalk;
    },
  },
});
