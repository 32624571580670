
import { Measurements } from "@/typings/Measurements";
import { AqRanges } from "@/typings/Sensor";
import { PropType, defineComponent } from "vue";
import { aqRangeColors } from "@/services/AirQuality";
import ProgressBar from "primevue/progressbar";
import AdvancedGaugeChartHome from "@/components/charts/AdvancedGaugeChartHome.vue";

export default defineComponent({
  props: {
    externalId: {
      type: Number,
    },
    payload: {
      type: Object as PropType<{
        name: string;
        measurements: Measurements;
      }>,
      required: true,
    },
  },
  data: () => ({
    aqRanges: {
      index: [50, 100, 150, 200, 250, 300],
      pm10: [20, 40, 50, 100, 150, 1200],
      pm2_5: [10, 20, 25, 50, 75, 800],
      o3: [50, 100, 130, 240, 380, 800],
      no2: [40, 90, 120, 230, 340, 1000],
      so2: [100, 200, 350, 500, 750, 1250],
      co: [7500, 10000, 12500, 20000],
      co2: [1000, 2000, 5000, 10000],
    } as AqRanges,
    aqRangeColors: aqRangeColors,
  }),
  methods: {
    getValueColorClass(type: keyof AqRanges, value: number | null) {
      if (value === null) {
        return "sensorGrey";
      }

      let ranges = this.aqRanges[type];

      return value > ranges[4]
        ? "sensorPurple"
        : value > ranges[3]
        ? "sensorRed"
        : value > ranges[2]
        ? "sensorOrange"
        : value > ranges[1]
        ? "sensorYellow"
        : value > ranges[0]
        ? "sensorGreen"
        : "sensorBlue";
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  components: { ProgressBar, AdvancedGaugeChartHome },
});
