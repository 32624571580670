
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";
import HCMore from "highcharts/highcharts-more";
import HCSolidGauge from "highcharts/modules/solid-gauge";

HCMore(Highcharts);
HCSolidGauge(Highcharts);

import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "AdvancedGaugeChartHome",
  props: {
    percentage: { type: Number, required: true },
    bands: {
      type: Array as PropType<
        {
          from: number;
          to: number;
          color: string;
          thickness: string;
        }[]
      >,
      default: null,
    },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
  },
  components: {
    highcharts: Chart,
  },
  methods: {
    setPercentage() {
      if (this.percentage !== undefined && this.percentage !== null) {
        return this.percentage;
      }
      return null;
    },
  },
  computed: {
    chartOptions() {
      return {
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          type: "gauge",
        },
        pane: {
          center: ["50%", "30%"],
          size: "90%",
          startAngle: -130,
          endAngle: 130,
          background: [],
        },
        yAxis: {
          min: this.min,
          max: this.max,
          lineWidth: 0,
          tickPositions: [],
          plotBands: this.bands,
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          gauge: {
            dataLabels: {
              enabled: false,
            },
            dial: {
              radius: "45%",
              backgroundColor: "#C9BCA9",
              baseWidth: 23,
              topWidth: 1,
              baseLength: "5%", // of radius
              rearLength: "0%",
            },
            pivot: {
              radius: 12,
              backgroundColor: "#C9BCA9",
            },
          },
        },
        series: [
          {
            data: [
              {
                radius: "72%",
                innerRadius: "65%",
                y: this.setPercentage(),
              },
            ],
          },
        ],
      };
    },
  },
});
