
import { defineComponent, markRaw } from "vue";
import SidebarWrapper from "@/components/SidebarWrapper.vue";
import SidebarAirQuality from "@/components/airQuality/SidebarAirQuality.vue";
import MapAirQuality from "@/components/airQuality/MapAirQuality.vue";
import Accordion, { AccordionTabOpenEvent } from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import ProgressBar from "primevue/progressbar";
import AdvancedGaugeChart from "@/components/charts/AdvancedGaugeChart.vue";
import { Sensor, AqRanges } from "@/typings/Sensor";
import HistoryGraph from "@/components/airQuality/HistoryGraph.vue";
import { aqRangeColors } from "@/services/AirQuality";
import { firebaseInstance } from "@/plugins/firebase";
import { getValueColorClass } from "@/services/Parking";

export default defineComponent({
  data: () => ({
    sidebarComponent: markRaw(SidebarAirQuality),
    mapComponent: markRaw(MapAirQuality),
    sensor: null as null | Sensor,
    aqRanges: {
      index: [50, 100, 150, 200, 250, 300],
      pm10: [20, 40, 50, 100, 150, 1200],
      pm2_5: [10, 20, 25, 50, 75, 800],
      o3: [50, 100, 130, 240, 380, 800],
      no2: [40, 90, 120, 230, 340, 1000],
      so2: [100, 200, 350, 500, 750, 1250],
      co: [7500, 10000, 12500, 20000],
      co2: [1000, 2000, 5000, 10000],
    } as AqRanges,
    aqRangeColors: aqRangeColors,
  }),
  mounted() {
    this.loadAirQualitySensor();
  },
  methods: {
    handleAccordionOpen(event: AccordionTabOpenEvent) {
      if (event.index == 0) {
        firebaseInstance?.analytics().logEvent("aqi_screen", {
          page: "aqi_screen",
          category: "aqi_sensor_item",
          category_desc: "Zaslon AQI odprt",
          sub_category: "air_quality_details_collapsed",
          sub_category_desc: "Uporabnik je zaprl harmoniko",
          ...this.$store.getters["user/analyticsInfo"],
        });
      }
      if (event.index == 1) {
        firebaseInstance?.analytics().logEvent("aqi_screen", {
          page: "aqi_screen",
          category: "aqi_sensor_item",
          category_desc: "Zaslon AQI odprt",
          sub_category: "air_quality_details_collapsed",
          sub_category_desc: "Uporabnik je razširil harmoniko",
          ...this.$store.getters["user/analyticsInfo"],
        });
      }
    },
    handleAccordionClose(event: AccordionTabOpenEvent) {
      if (event.index == 0) {
        firebaseInstance?.analytics().logEvent("aqi_screen", {
          page: "aqi_screen",
          category: "aqi_sensor_item",
          category_desc: "Zaslon AQI odprt",
          sub_category: "air_quality_details_collapsed",
          sub_category_desc: "Uporabnik je zaprl harmoniko",
          ...this.$store.getters["user/analyticsInfo"],
        });
      }
      if (event.index == 1) {
        firebaseInstance?.analytics().logEvent("aqi_screen", {
          page: "aqi_screen",
          category: "aqi_sensor_item",
          category_desc: "Zaslon AQI odprt",
          sub_category: "air_quality_details_collapsed",
          sub_category_desc: "Uporabnik je razširil harmoniko",
          ...this.$store.getters["user/analyticsInfo"],
        });
      }
    },
    loadAirQualitySensor() {
      if (
        Object.prototype.hasOwnProperty.call(this.$route.params, "id") === false
      ) {
        return;
      }
      this.$http
        .get("api/v2/air-quality/" + this.$route.params.id)
        .then((response) => {
          this.sensor = response.data;
        });
    },
    getValueColorClass(type: keyof AqRanges, value: number | null) {
      if (value === null) {
        return "sensorGrey";
      }

      let ranges = this.aqRanges[type];

      return value > ranges[4]
        ? "sensorPurple"
        : value > ranges[3]
        ? "sensorRed"
        : value > ranges[2]
        ? "sensorOrange"
        : value > ranges[1]
        ? "sensorYellow"
        : value > ranges[0]
        ? "sensorGreen"
        : "sensorBlue";
    },
    getSensorIndexText(value: number | null) {
      if (value === null) {
        return this.$t("air_quality.good");
      }

      return value <= 50
        ? this.$t("air_quality.very_good")
        : value <= 100
        ? this.$t("air_quality.good")
        : value <= 150
        ? this.$t("air_quality.moderate")
        : value <= 200
        ? this.$t("air_quality.poor")
        : value <= 250
        ? this.$t("air_quality.very_poor")
        : this.$t("air_quality.extremely_poor");
    },
  },
  computed: {
    tableDays() {
      let days = this.$moment.weekdaysShort();
      return days.splice(this.$moment().day() + 1).concat(days);
    },
    tableDates() {
      let dates = [];
      var lastDate = this.$moment().local().subtract(6, "days").startOf("day");
      var currDate = this.$moment(new Date()).local().startOf("day");
      while (lastDate.isSameOrBefore(currDate)) {
        dates.push(lastDate.format("DD.MM"));
        lastDate.add(1, "days");
      }
      return dates;
    },
  },
  watch: {
    "$route.params.id"() {
      this.loadAirQualitySensor();
    },
  },
  components: {
    AdvancedGaugeChart,
    Accordion,
    AccordionTab,
    ProgressBar,
    SidebarWrapper,
    HistoryGraph,
  },
});
