
// <reference types="google.maps" />

import { defineComponent } from "vue";
import { Coordinates, Marker } from "@/typings/Marker";
import { GeoJson } from "@/typings/GeoJson";

export default defineComponent({
  data: () => ({
    temporaryCenter: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    hoveredMarker: null as Marker | null,
    infoWindowPosition: null as null | Coordinates,
    // eslint-disable-next-line no-undef
    map: null as google.maps.Map | null,
  }),
  props: {
    center: {
      type: Object as () => Coordinates,
      default: () => ({
        lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
        lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
      }),
    },
    markers: {
      type: Array as () => Marker[],
      default: () => {
        return [];
      },
    },
    zoom: {
      type: Number,
      default: 15,
    },
    markerInfoOnHover: Boolean,
    navigateOnClick: Boolean,

    geoJson: {
      type: Array as () => GeoJson[],
    },
    selectedGeoJson: {
      type: Object as () => GeoJson,
    },
    fitMarkers: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    if (this.markers !== null && this.markers.length === 1) {
      this.$emit("centerChanged", this.markers[0].position);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.mapRef as any).$mapPromise.then(
      // eslint-disable-next-line no-undef
      (mapObject: google.maps.Map) => {
        this.map = mapObject;

        this.fitMarkersToMap();
        this.addCirclesToPolylines();
      },
    );
  },

  watch: {
    selectedGeoJson(geoJson: GeoJson | null) {
      if (geoJson === null) {
        return;
      }

      this.infoWindowPosition = geoJson.path[0];
    },
    markers: {
      handler(newMarkers) {
        if (this.fitMarkers && newMarkers.length > 0) {
          this.$nextTick(() => {
            this.fitMarkersToMap();
          });
        }
      },
      deep: true,
    },
  },

  methods: {
    decodePath(path: string) {
      // eslint-disable-next-line no-undef
      return google.maps.geometry.encoding.decodePath(path);
    },
    openInfoWindow(item: GeoJson) {
      this.infoWindowPosition = item.path[0];

      this.$emit("selectGeoJson", item.payload);
    },
    openInfo(marker: Marker) {
      this.$emit("selectMarker", marker.payload);
      if (
        marker.on_click_route_name === null ||
        this.navigateOnClick === false
      ) {
        return;
      }
      this.$router.push({
        name: marker.on_click_route_name,
        params: { id: marker.id },
      });
      this.$emit("close");
    },
    hoverOpen(marker: Marker) {
      if (this.markerInfoOnHover === false) {
        return;
      }

      this.hoveredMarker = marker;
    },
    updateTemporaryCenter(event: { lat(): number; lng(): number }) {
      // This event is called every time center coordinates changes.
      // If we update center property directly, map freezes.
      // Thats why we only update center when dragend event fires
      this.temporaryCenter = { lat: event.lat(), lng: event.lng() };
    },
    updateCenter() {
      this.$emit("centerChanged", this.temporaryCenter);
    },
    updateZoom(value: number) {
      // Zoom event is called twice for some reason
      if (this.zoom === value) {
        return;
      }

      this.$emit("zoomChanged", value, this.temporaryCenter);
    },
    fitMarkersToMap() {
      if (!this.map || this.markers.length === 0) {
        return;
      }

      // eslint-disable-next-line no-undef
      const bounds = new google.maps.LatLngBounds();

      this.markers.forEach((marker) => {
        bounds.extend(marker.position);
      });

      this.map.fitBounds(bounds);
    },
    getPolylineIcons(isDashed?: boolean) {
      return isDashed
        ? [
            // Display dashed line
            {
              icon: {
                path: "M 0,-1 0,1",
                strokeOpacity: 1,
                scale: 4,
              },
              offset: "0",
              repeat: "20px",
            },
          ]
        : [];
    },
    addCirclesToPolylines() {
      if (!this.map) {
        return;
      }

      this.geoJson?.forEach((item) => {
        if (
          item.geometry.type === "LineString" &&
          item.path.length > 1 &&
          item.properties?.showCircles
        ) {
          // Get the correct path coordinates
          const pathCoords = item.geometry.geometryString
            ? this.decodePath(item.geometry.geometryString)
            : item.path;

          // eslint-disable-next-line no-undef
          new google.maps.Marker({
            position: pathCoords[pathCoords.length - 1],
            map: this.map,
            icon: {
              // eslint-disable-next-line no-undef
              path: google.maps.SymbolPath.CIRCLE,
              scale: 6,
              fillColor: item.color,
              fillOpacity: 1,
              strokeColor: "white",
              strokeWeight: 2,
            },
          });
        }
      });
    },
  },
});
