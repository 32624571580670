
import { defineComponent } from "vue";
import ProgressBar from "primevue/progressbar";
import { AirQualitySensor } from "@/typings/AirQualitySensor";
import { QueryParameters } from "@/typings/QueryParameters";
import InfinityScroll from "@/components/InfinityScroll.vue";
import { Paginated } from "@/typings/Paginated";
import { aqRanges } from "@/services/AirQuality";
// import FilterTabs from "@/components/customComponents/FilterTabs.vue";

export default defineComponent({
  data: () => ({
    sensors: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<AirQualitySensor>,
    isLoading: false,
    aqRanges: aqRanges,
  }),
  mounted() {
    this.loadAirQualitySensors();
  },
  methods: {
    loadAirQualitySensors(page = 1) {
      let cityId = process.env.VUE_APP_CITY_ID;
      let params: QueryParameters = {
        filters: {
          city_id: cityId,
        },
        page,
        perPage: 20,
      };
      this.isLoading = true;
      this.$http
        .get("api/v2/air-quality", { params })
        .then((response) => {
          if (page !== 1) {
            this.sensors.data = this.sensors.data.concat(response.data.data);
            this.sensors.current_page = response.data.current_page;
            return;
          }
          this.sensors = response.data;

          if (
            "id" in this.$route.params === true ||
            this.sensors.data.length === 0
          ) {
            return;
          }

          let firstSensor: AirQualitySensor = this.sensors.data[0];
          this.$router.push({
            name: "AirQualityById",
            params: { id: firstSensor.id },
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getSensorColorClass(index: number | null) {
      if (index === null) {
        return "sensorGrey";
      }

      let ranges = this.aqRanges.index;

      return index > ranges[4]
        ? "sensorPurple"
        : index > ranges[3]
        ? "sensorRed"
        : index > ranges[2]
        ? "sensorOrange"
        : index > ranges[1]
        ? "sensorYellow"
        : index > ranges[0]
        ? "sensorGreen"
        : "sensorBlue";
    },
  },
  components: {
    InfinityScroll,
    // FilterTabs,
    ProgressBar,
  },
});
