
import { defineComponent } from "vue";
import { Coordinates, Marker } from "@/typings/Marker";
import Map from "@/components/Map.vue";
import ModeFilter from "@/components/routePlanner/ModeFilter.vue";
import GreenRouteCards from "@/components/routePlanner/GreenRouteCards.vue";
import { Journey, Route } from "@/typings/RoutePlanner";
import RouteCard from "@/components/routePlanner/RouteCard.vue";
import EmissionFilter from "@/components/routePlanner/EmissionFilter.vue";
import EffortFilter from "@/components/routePlanner/EffortFilter.vue";
import WeatherAlert from "@/components/routePlanner/WeatherAlert.vue";
import Divider from "primevue/divider";
import IconAdjustmentsAlt from "~icons/tabler/adjustments-alt";
import IconChevronDown from "~icons/tabler/chevron-down";
import IconChevronRight from "~icons/tabler/chevron-right";

export default defineComponent({
  components: {
    Map,
    ModeFilter,
    GreenRouteCards,
    RouteCard,
    EmissionFilter,
    EffortFilter,
    Divider,
    WeatherAlert,
    IconAdjustmentsAlt,
    IconChevronDown,
    IconChevronRight,
  },

  data: () => ({
    journey: null as null | Journey,
    isLoading: false,
    isAddAddressModalOpen: false,
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    zoom: 15,
    selectedMode: "all",
    selectedEmission: 0,
    selectedEffort: 0,
    isFilterOpen: false,
  }),

  methods: {
    getRouteData() {
      this.isLoading = true;

      this.$maas
        .get("/api/v1/routing/journey", {
          params: {
            startLat: this.$route.query.startLocLat,
            startLon: this.$route.query.startLocLon,
            endLat: this.$route.query.endLocLat,
            endLon: this.$route.query.endLocLon,
            eventType:
              this.$route.query.eventType === "arrival"
                ? "arrival"
                : "departure",
            eventAt: this.$route.query.eventAt,
            ...this.mapModes(),
          },
        })
        .then((response) => {
          this.journey = response.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateCenter(value: Coordinates) {
      this.center = value;
    },
    updateZoom(value: number) {
      this.zoom = value;
    },
    mapModes() {
      const modesString = this.$route.query.modes as string;

      if (!modesString) {
        return;
      }

      const validModes = ["walk", "bicycle", "bicycle_rent", "car", "transit"];

      const modes = modesString
        .split(",")
        .filter((mode) => validModes.includes(mode));

      return {
        modes: modes.filter((mode) => mode !== "wheelchairFriendly").join(","),
        wheelchairFriendly: modes.includes("wheelchairFriendly")
          ? 1
          : undefined,
        weightCo2: this.selectedEmission,
        weightHumanEffort: this.selectedEffort,
      };
    },
  },

  computed: {
    isRoutesView() {
      return this.$route.name === "RoutesPlannerView";
    },
    hasRouteQuery() {
      return (
        this.$route.query.startLocLat !== undefined &&
        this.$route.query.startLocLon !== undefined &&
        this.$route.query.endLocLat !== undefined &&
        this.$route.query.endLocLon !== undefined
      );
    },
    routeMarkers() {
      return [
        {
          position: {
            lat: parseFloat(this.$route.query.startLocLat as string),
            lng: parseFloat(this.$route.query.startLocLon as string),
          },
          icon: "/markers/start-pin.svg",
        },
        {
          position: {
            lat: parseFloat(this.$route.query.endLocLat as string),
            lng: parseFloat(this.$route.query.endLocLon as string),
          },
          icon: "/markers/end-pin.svg",
        },
      ] as Array<Marker>;
    },
    filteredRoutes(): Array<Route> {
      if (!this.journey?.routes) {
        return [];
      }

      if (this.selectedMode === "all") {
        return this.journey.routes;
      }

      if (this.selectedMode === "walk") {
        return this.journey.routes.filter((route) => {
          return route.legs.length === 1 && route.legs[0].mode === "WALK";
        });
      }

      return this.journey.routes.filter((route) => {
        return route.legs.some(
          (leg) => leg.mode === this.selectedMode.toUpperCase(),
        );
      });
    },
    hasJourney() {
      return (
        !this.isLoading &&
        this.journey !== null &&
        Object.keys(this.journey).length > 0
      );
    },
  },

  watch: {
    "$route.query": {
      handler() {
        if (this.isRoutesView && this.hasRouteQuery) {
          this.getRouteData();
        }
      },
      immediate: true,
      deep: true,
    },
    selectedEmission() {
      this.getRouteData();
    },
    selectedEffort() {
      this.getRouteData();
    },
  },
});
